import rangeSlider from 'rangeslider-pure';

const inputSlider = () => {
    const _R = document.querySelectorAll('.input-slider');

    if (!_R.length) {
      return;
    }

    Array.from(_R).forEach((slider) => {

      const sliderInput =  slider.querySelector('input[type="range"]');
      const tooltip = document.createElement('div');
      const largetooltip = document.createElement('h1');
      const step = sliderInput.getAttribute('step');
      const plusBtn = slider.querySelector('.button-circle--plus');
      const minusBtn = slider.querySelector('.button-circle--minus');

      let sliderPrefix = '';

      if (slider.hasAttribute('data-tooltip')) {
        sliderPrefix = slider.dataset.tooltipPrefix;
      }

      if (slider.hasAttribute('data-largetooltip')) {
        sliderPrefix = slider.dataset.largetooltipPrefix;
      }


      rangeSlider.create(sliderInput, {
        onSlide: (value, percent, position) => {

          if( slider.hasAttribute('data-tooltip') ) {
            tooltip.textContent = sliderPrefix + value;
          }
          if( slider.hasAttribute('data-largetooltip') ) {
              largetooltip.textContent = sliderPrefix + value.toLocaleString("en");
          }
        }
      });


      if( slider.hasAttribute('data-tooltip') ) {
        const handleEl = sliderInput.rangeSlider.handle;
        tooltip.classList.add('tooltip');
        handleEl.appendChild(tooltip);
        tooltip.textContent = sliderPrefix + sliderInput.rangeSlider.value;
      }

      if( slider.hasAttribute('data-largetooltip') ) {
        const largeInput = slider.closest('.container').querySelector('.input-heading');
        largetooltip.classList.add('largetooltip');
        largeInput.appendChild(largetooltip);
        largetooltip.textContent = sliderPrefix + sliderInput.rangeSlider.value.toLocaleString("en");
      }

      // Plus and minus buttons
        var txtMinimumBorrowAmount = $("#txt_MinimumBorrowAmount").text();
        var txtMaximumBorrowAmount = $("#txt_MaximumBorrowAmount").text();
        $("#txt_MinimumBorrowAmount").text(parseFloat(txtMinimumBorrowAmount).toLocaleString("en"));
        $("#txt_MaximumBorrowAmount").text(parseFloat(txtMaximumBorrowAmount).toLocaleString("en"));

      if (plusBtn) {
        // We have a plus button
        plusBtn.addEventListener('click', (e) => {
          e.preventDefault();

          sliderInput.rangeSlider.update({
            value: (sliderInput.rangeSlider.value + parseInt(step))
          }, true);

        });

      }

      if (minusBtn) {
        // We have a minus button
        minusBtn.addEventListener('click', (e) => {
          e.preventDefault();

          sliderInput.rangeSlider.update({
            value: (sliderInput.rangeSlider.value - parseInt(step))
          }, true);

        });
      }


    });

}

export default inputSlider
import jQuery from 'jquery'
import Lottie from 'lottie-web'

const lottie_animations = () => {

    const lottieHalfImageContainers = document.querySelectorAll(".lottie-half-image-full");
    lottieHalfImageContainers.forEach(el => {
        Lottie.loadAnimation({
            container: el,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: '/sunflower/src/img/lottie/home-page-hero.json'
        });
    });

  // Home page
    if ($('.lottie').length > 0) {
    Lottie.loadAnimation({
      container: document.getElementById('lottie-full-image'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: '/sunflower/src/img/lottie/home-page-hero.json'
    });
  }

  $('.lottie-dynamic').each(function (index) {
    Lottie.loadAnimation({
      container: $(this)[0],
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: $(this).data('lottie')
    })
  })

}


export default lottie_animations
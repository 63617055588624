import Choices from 'choices.js'

export default class Calculator {

    constructor() {

        const selects = document.querySelectorAll('.calculator--select');
        
        var choices = [];

        for (let i = 0; i < selects.length; i++) {

            choices[i] = new Choices(selects[i], { searchEnabled: false, itemSelectText: "" });
        }

        const calcs = jQuery('.calcContainer')
        var oldRangeValue = 0;

        for(let i = 0; i < calcs.length; i++) {
            var apr = jQuery(calcs[i]).data('apr') / 100;
            var link = jQuery(calcs[i]).find('.check-eligibility-btn').attr('href') + '?';
            var applyButton = jQuery(calcs[i]).find('.check-eligibility-btn');
    
            //set initial values
            var values = this.setValues(calcs[i]);
            oldRangeValue = values.loanAmount;
            if (values.loanAmount <= 2000) {
                apr = jQuery(calcs[i]).data('regiona') / 100;
            }
            else if (values.loanAmount > 2000 && values.loanAmount <= 3000) {
                apr = jQuery(calcs[i]).data('regionb') / 100;
            }
            else if (values.loanAmount > 3000 && values.loanAmount <= 5000) {
                apr = jQuery(calcs[i]).data('regionc') / 100;
            }
            else {
                apr = jQuery(calcs[i]).data('regiond') / 100;
            }
            this.updateElements(calcs[i], values, apr);
    
            applyButton.on('click', (e) => {
    
                //set the link href
                e.preventDefault();
    
                if (!jQuery(calcs[i]).find('.calculator-use-of-loan').val()) {
                    jQuery('html, body').animate({
                        scrollTop:  jQuery(calcs[i]).find('.reason-for-borrowing').offset().top - 100
                    });
                    jQuery(calcs[i]).find('.reason-for-borrowing').addClass('input-wrap--select--error');
                    jQuery(calcs[i]).find('span.error-msg').show();
                    e.preventDefault();
                } else {
                    //submit the form
                    window.open(jQuery(calcs[i]).find('.check-eligibility-btn').attr('href'), '_blank');
                }
            });
    
            // Default calc range input to minimum 
            jQuery(calcs[i]).find('.calculator-input').on('change', () => {
                var newRangeValue = jQuery(calcs[i]).find('.calculator-range').val();
                if (oldRangeValue > 5000 && newRangeValue < 5000) {
                    if (newRangeValue > 3000 && newRangeValue <= 5000) {
                        if (jQuery(calcs[i]).find('.calculator-duration').val() > 48) {
                            $('#ddlLoanTerm')
                                .find('option')
                                .remove()
                                .end()
                                .append('<option value="48">48 months</option>')
                                .val('48');
                            var elements = document.getElementsByClassName("choices__item--selectable");
                            for (var n = 0; n < elements.length; n++) {
                                elements[n].innerHTML = "48 months";
                                break;
                            }
                        }
                    }
                    else {
                        if (jQuery(calcs[i]).find('.calculator-duration').val() > 36) {
                            $('#ddlLoanTerm')
                                .find('option')
                                .remove()
                                .end()
                                .append('<option value="36">36 months</option>')
                                .val('36');
                            var elements = document.getElementsByClassName("choices__item--selectable");
                            for (var n = 0; n < elements.length; n++) {
                                elements[n].innerHTML = "36 months";
                                break;
                            }
                        }
                    }
                }
                else if ((oldRangeValue > 3000 && oldRangeValue <= 5000) && (newRangeValue <= 3000)) {
                    if (jQuery(calcs[i]).find('.calculator-duration').val() > 36) {
                        $('#ddlLoanTerm')
                            .find('option')
                            .remove()
                            .end()
                            .append('<option value="36">36 months</option>')
                            .val('36');
                        var elements = document.getElementsByClassName("choices__item--selectable");
                        for (var n = 0; n < elements.length; n++) {
                            elements[n].innerHTML = "36 months";
                            break;
                        }
                    }
                }
                var values = this.setValues(calcs[i]);
                oldRangeValue = values.loanAmount;
                if (values.loanAmount <= 2000) {
                    apr = jQuery(calcs[i]).data('regiona') / 100;
                }
                else if (values.loanAmount > 2000 && values.loanAmount <= 3000) {
                    apr = jQuery(calcs[i]).data('regionb') / 100;
                }
                else if (values.loanAmount > 3000 && values.loanAmount <= 5000) {
                    apr = jQuery(calcs[i]).data('regionc') / 100;
                }
                else {
                    apr = jQuery(calcs[i]).data('regiond') / 100;
                }
                this.updateElements(calcs[i], values, apr);
    
    
                values = this.setValues(calcs[i]);
                if (values.loanAmount <= 2000) {
                    apr = jQuery(calcs[i]).data('regiona') / 100;
                }
                else if (values.loanAmount > 2000 && values.loanAmount <= 3000) {
                    apr = jQuery(calcs[i]).data('regionb') / 100;
                }
                else if (values.loanAmount > 3000 && values.loanAmount <= 5000) {
                    apr = jQuery(calcs[i]).data('regionc') / 100;
                }
                else {
                    apr = jQuery(calcs[i]).data('regiond') / 100;
                }
                this.updateElements(calcs[i], values, apr);
            });

            jQuery(calcs[i]).find('.calculator-use-of-loan').on('change', function () {
                jQuery(calcs[i]).find('span.error-msg').hide();
                jQuery(calcs[i]).find('.reason-for-borrowing').removeClass('input-wrap--select--error');
            });
        }
        for (var k = 0; k < jQuery('.callToAction').length; k++) {
            jQuery(jQuery('.callToAction')[k]).find('.check-eligibility-btn').on('click', function (e) {
                for (var m = 0; m < jQuery('.calcContainer').length; m++) {
                    e.preventDefault();
                    if (!jQuery(jQuery('.calcContainer')[m]).find('.calculator-use-of-loan').val()) {
                        jQuery('html, body').animate({
                            scrollTop: jQuery(jQuery('.calcContainer')[m]).find('.reason-for-borrowing').offset().top - 100
                        });
                        jQuery(jQuery('.calcContainer')[m]).find('.reason-for-borrowing').addClass('input-wrap--select--error');
                        jQuery(jQuery('.calcContainer')[m]).find('span.error-msg').show();
                        e.preventDefault();
                    }
                    else {
                        window.open(jQuery(jQuery('.calcContainer')[m]).find('.check-eligibility-btn').attr('href'), '_blank');
                    }
                }
            });
        }
    }

    setValues(el) {
        var values = {
            loanAmount: jQuery(el).find('.calculator-range').val(),
            loanDuration: jQuery(el).find('.calculator-duration').val(),
            loanReason: jQuery(el).find('.calculator-use-of-loan').val()
        }

        return values
    }

    updateElements(el, values, apr) {
        let payment = (values.loanAmount * ((1 + apr) ** (1 / 12) - 1) / (1 - (1 + ((1 + apr) ** (1 / 12) - 1)) ** (-values.loanDuration))).toFixed(2);
        let totalAmountPayable = (((values.loanAmount * ((1 + apr) ** (1 / 12) - 1) / (1 - (1 + ((1 + apr) ** (1 / 12) - 1)) ** (-values.loanDuration)))) * values.loanDuration).toFixed(2);
        let totalCostOfCredit = (totalAmountPayable);

        jQuery(el).find('.borrowing-amount').html(parseFloat(values.loanAmount).toLocaleString("en"));
        jQuery(el).find('.repayment-length').html(jQuery(el).find('.calculator-duration:checked').val());
        jQuery(el).find('.monthly-payment').html(payment);
        jQuery(el).find('.total-cost-of-credit').html(parseFloat(totalCostOfCredit).toLocaleString("en"));

        var originalLink = jQuery(el).find('.check-eligibility-btn').data('baseurl');
        jQuery(el).find('#aprPercentage').html((apr * 100).toFixed(1)).append('%');
        jQuery(el).find('#interestRatePA').html((((Math.pow(1 + apr, 1 / 12) - 1) * 100) * 12).toFixed(1)).append('%');
        //set the link parameters
        var link = originalLink + '?';
        link += 'LTer%3D' + jQuery(el).find('.calculator-duration').val();
        link += '%26LVal%3D' + jQuery(el).find('.calculator-range').val();
        link += '%26LRes%3D' + jQuery(el).find('.calculator-use-of-loan').val().replace("/", "%2F");
        link += '%26MKT%3D' + jQuery(el).find('#MKT').val();
        link += '%26SEO%3D' + jQuery(el).find('#SEO').val();
        console.log(link);

        jQuery(el).find('.check-eligibility-btn').attr('href', link);

        if (values.loanAmount > 3000 && values.loanAmount <= 5000) {
            if ($("#choices--ddlLoanTerm-item-choice-6").length) {
                $("#choices--ddlLoanTerm-item-choice-6").show();
            }
        }
        else {
            if (values.loanAmount <= 3000) {
                if ($("#choices--ddlLoanTerm-item-choice-6").length) {
                    $("#choices--ddlLoanTerm-item-choice-6").hide();
                }
            }
        }

        if (values.loanAmount > 5000) {
            if ($("#choices--ddlLoanTerm-item-choice-6").length) {
                $("#choices--ddlLoanTerm-item-choice-6").show();
            }
            if ($("#choices--ddlLoanTerm-item-choice-7").length) {
                $("#choices--ddlLoanTerm-item-choice-7").show();
            }
        }
        else {
            if ($("#choices--ddlLoanTerm-item-choice-7").length) {
                $("#choices--ddlLoanTerm-item-choice-7").hide();
            }
        }
        if ($("#choices--ddlLoanPurpose-item-choice-9").length) {
            $("#choices--ddlLoanPurpose-item-choice-9").hide();
        }
        for (var j = 0; j < jQuery('.callToAction').length; j++) {
            if (jQuery(jQuery('.callToAction')[j]).find('.check-eligibility-btn').text().toLowerCase() == "start your application") {
                jQuery(jQuery('.callToAction')[j]).find('.check-eligibility-btn').attr('href', link);
            }
        }
    }

}


import jQuery from 'jquery'

const faqs = () => {

    var tabTriggerBtns = document.querySelectorAll('.faq-subcategory__type'); //select
    var tabTriggerBtnsSelect = document.querySelectorAll('.faq-subcategory__type--select');

    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);

    if (searchParams.has('tab')) {
        var activeTab = searchParams.get('tab');

        var currentTabData = document.querySelector('.tab-content[data-tab-content="' + activeTab + '"]');
        var currentTabButton = document.querySelector('.faq-subcategory__type[data-tab-trigger="' + activeTab + '"]');
        // remove classes
        document.querySelector('.tab-content.active').classList.remove('active');
        document.querySelector('.faq-subcategory__type.is-active').classList.remove('is-active');

        // add classes
        currentTabData.classList.add('active');
        currentTabButton.classList.add('is-active');
    }

    let loadMoreBtns = document.querySelectorAll('.load-more');
    let tabs = document.querySelectorAll('.tab-content');

    Array.from(tabs).forEach((tab) => {
        let accordionHeaders = tab.querySelectorAll('.faq-accordion__header');

        if (accordionHeaders.length > 5) {

            var i = 0;

            Array.from(accordionHeaders).forEach((accordionHeader) => {
                i = i + 1;
                var parent = findAncestor(accordionHeader, 'faq-accordion__single');
                if (i > 5) {
                    parent.classList.add('faq-hide')
                }
            });

        } else {
            if (tab.querySelector('.load-more') != null) {
                tab.querySelector('.load-more').style.display = 'none';
            }
        }

        Array.from(accordionHeaders).forEach((accordionHeader) => {
            accordionHeader.addEventListener('click', (e) => {
                e.preventDefault();

                let $target = e.target;
                if ($target.closest('.faq-accordion__single').classList.contains('open')) {

                    setTimeout(function () {
                        $target.closest('.faq-accordion__single').classList.toggle('open');
                    }, 0);
                    return;
                }

                Array.from(accordionHeaders).forEach((accordionHeader) => {
                    accordionHeader.closest('.faq-accordion__single').classList.remove('open');
                });

                $target.closest('.faq-accordion__single').classList.toggle('open');
            });
        });
    });

    Array.from(loadMoreBtns).forEach((btn) => {
        let siblingFaqs = btn.parentNode.querySelectorAll('.faq-accordion__single');

        if (siblingFaqs.length < 5) {
            btn.style.display = "none";
        }

        btn.addEventListener('click', function (e) {
            let i = 0;

            let accordions = btn.parentNode.querySelectorAll('.faq-hide');

            Array.from(accordions).forEach((accordion) => {
                if (i <= 5) {
                    accordion.classList.remove('faq-hide');

                    let remaining_accordions = btn.parentNode.querySelectorAll('.faq-hide');

                    if (remaining_accordions.length == 0) {
                        btn.style.display = "none";
                    }
                }
            });
        });
    });

    tabTriggerBtnsSelect.forEach(function (tabTriggerBtnsSelect, index) {
        tabTriggerBtnsSelect.addEventListener('change', function () {

            let selectedI = $('.choices__item').attr("data-value");
            var currentTabData = document.querySelector('.tab-content[data-tab-content="' + selectedI + '"]');

            // remove classes
            document.querySelector('.tab-content.active').classList.remove('active');

            // add classes
            currentTabData.classList.add('active');
            this.classList.add('is-active');
        });
    });

    tabTriggerBtns.forEach(function (tabTriggerBtn, index) {
        tabTriggerBtn.addEventListener('click', function () {

            var currentTabData = document.querySelector('.tab-content[data-tab-content="' + this.dataset.tabTrigger + '"]');
            // remove classes
            document.querySelector('.tab-content.active').classList.remove('active');
            document.querySelector('.faq-subcategory__type.is-active').classList.remove('is-active');

            // add classes
            currentTabData.classList.add('active');
            this.classList.add('is-active');
        });
    });

    // Change hash for page-reload
    $('.nav-tabs a').on('shown', function (e) {
        window.location.hash = e.target.hash;
    })

    function findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }
}

export default faqs
const contact_us = () => {


    jQuery('.intention-selector').on('change', function() {
        var val = jQuery(this).val();
        jQuery('.option').hide();

        jQuery('.option-'+val).show();
    })
}

export default contact_us
const hero_carousel = (Splide) => {
    var carousels = document.querySelectorAll('.hero-carousel-text')

    if (carousels.length > 0) {
        for (let y = 0; y < carousels.length; y++) {
            new Splide(carousels[y], {
                perPage: 3,
                trimSpace: false,
                focus: 'center',
                gap: 40,
                padding: {
                    right: 120,
                    left: 0
                },
                fixedWidth: 600,
                breakpoints: {
                    992: {
                        perPage: 2,
                        padding: 0,
                        fixedWidth: 450
                    },
                    768: {
                        perPage: 1,
                        padding: 0,
                        fixedWidth: 600,
                    },
                    600: {
                        fixedWidth: 320,
                    }
                }
            }).mount();
        }
    }

    var carousels = document.querySelectorAll('.hero-carousel-images')

    if (carousels.length > 0) {
        for (let y = 0; y < carousels.length; y++) {
            new Splide(carousels[y], {
                perPage: 2, 
                gap: 40,
                focus: 'center',
                trimSpace: false,
                padding: {
                    right: 120,
                    left: 0
                },
                fixedWidth: 600,
                breakpoints: {
                    992: {
                        perPage: 2,
                        padding: 0,
                        fixedWidth: 450
                    },
                    768: {
                        perPage: 1,
                        padding: 0,
                        fixedWidth: 600,
                    },
                    600: {
                        fixedWidth: 320,
                        padding: 0,
                    }
                }
            }).mount();
        }
    }

    var carousels = document.querySelectorAll('.hero-carousel-videos')

    if (carousels.length > 0) {
        for (let y = 0; y < carousels.length; y++) {
            new Splide(carousels[y], {
                perPage: 2, 
                gap: 40,
                trimSpace: false,
                focus: 'center',
                padding: {
                    right: 120,
                    left: 0
                },
                fixedWidth: 600,
                breakpoints: {
                    992: {
                        perPage: 2,
                        padding: 0,
                        fixedWidth: 450
                    },
                    768: {
                        perPage: 1,
                        padding: 0,
                        fixedWidth: 600,
                    },
                    600: {
                        fixedWidth: 320,
                        padding: 0,
                    }
                }
            }).mount();
        }
    }


    carousels = document.querySelectorAll('.card-carousel--container')

    if (carousels.length > 0) {
        for (let y = 0; y < carousels.length; y++) {
            var splide = new Splide(carousels[y], {
                perPage: 1,
                fixedWidth: 300, 
                fixedHeight: 450, 
                gap: 20,
                focus: 'center'
            }).mount();

            var bar = splide.root.querySelector('.card-carousel--progress-bar');

            // Update the bar width:
            splide.on('mounted move', function () {
                var end = splide.Components.Controller.getEnd() + 1;
                bar.style.width = String(100 * (splide.index + 1) / end) + '%';
            });
        }


    }
}

export default hero_carousel
const gated_content = () => {

    
    var cookieName = window.location.pathname + 'Verified'
    cookieName = cookieName.replace(/\//g, '_');
    console.log(cookieName)

    jQuery('#setVerifiedCookie').on('click', function(){
       setCookie(cookieName, 'verified').then(function() {
            window.location.reload()
       })
    })
}

function setCookie(name,value) {
    return new Promise((resolve, reject) => {
        document.cookie = name + "=" + (value || "")  + "; path=/"
        resolve()  
    })
}
export default gated_content;
const alert = () => {

  var navHeight = $('.top-fixed-container').height();

  $('.page-content').css('margin-top', navHeight + "px")

  $(".alert--close").on('click', function () {
    var id = $(this).data('alert-id')
    $('#' + id).css('opacity', 1)
      .animate(
        { opacity: 0, height: 0 },
        { queue: false, duration: 500 }
      )

    setTimeout(function () {
      navHeight = $('.top-fixed-container').height();
      $('.page-content').animate(
        { marginTop: navHeight },
        { queue: false, duration: 500 }
      )
    }, 500)
  })
}

export default alert
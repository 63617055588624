const list_accordion = () => {


    var accordions = $('.list-accordion')

    console.log(accordions)

    if( accordions.length > 0 ){

        for( let i = 0; i < accordions.length; i++ ) {
            $(accordions[i]).find('.list-card--left').first().addClass('list-card--left-active')
            $(accordions[i]).find('.list-card--title').first().addClass('list-card--title-active')
            $(accordions[i]).find('.list-card--copy').first().show();
            $(accordions[i]).find('.list-card--right').first().show();
            $(accordions[i]).find('.list-card-accordion').first().show();
            $(accordions[i]).find('.list-card-accordion-tab').first().hide();


            $(accordions[i]).find('.list-card--left').on('click', function(){
                var title = $(this).data('listtitle')
                $(accordions[i]).find('.list-card--left').removeClass('list-card--left-active')
                $(accordions[i]).find('.list-card--title').removeClass('list-card--title-active')
                $(accordions[i]).find('.list-card--right').hide();
                $(accordions[i]).find('.list-card--left').not(this).find('.list-card--copy').hide();
                setTimeout(function() {
                    $(accordions[i]).find('.list-card--left[data-listtitle="'+title+'"]').addClass('list-card--left-active')
                    $(accordions[i]).find('.list-card--left[data-listtitle="'+title+'"]').find('.list-card--title').addClass('list-card--title-active')
                    $(accordions[i]).find('.list-card--right[data-listtitle="'+title+'"]').show();
                    $(accordions[i]).find('.list-card--left[data-listtitle="'+title+'"]').find('.list-card--copy').show();
                },50);
            });


            $(accordions[i]).find('.list-card-accordion-tab').on('click', function() {
                var title = $(this).data('listtitle')
                $(accordions[i]).find('.list-card-accordion-tab').show();
                $(accordions[i]).find('.list-card-accordion').slideUp();
                $(this).hide();
                $(accordions[i]).find('.list-card-accordion[data-listtitle="'+title+'"]').slideDown();
            })
        }

    }
}

export default list_accordion;
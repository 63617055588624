import flatpickr from "flatpickr";

const input_datepicker = () => {

    const dateInputs = document.querySelectorAll('.flatpickr');

    dateInputs.forEach(el => {

        const dob_label = el.nextElementSibling
        const shadow = el.parentNode.querySelector(".datepickerfieldshadow");

        console.log(el, dob_label, shadow);

        flatpickr(el, {
            maxDate: "today",
            dateFormat: "d/m/Y",
            onOpen: function (selectedDates, dateStr, instance) {
                dob_label.style.display = "block";
            },
            onClose: function (selectedDates, dateStr, instance) {
                dob_label.style.display = "none";
                dob_label.parentNode.classList.remove('hide-placeholder');
            },
            onChange: function (selectedDates, dateStr, instance) {
                shadow.value = selectedDates[0].toISOString();
            }
        });

    });
}

export default input_datepicker;
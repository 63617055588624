import Choices from 'choices.js'

const faq_choices = () => {

    let selects = document.querySelectorAll('.faqs-intro .input-wrap--select');
    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);

        if (!selects.length) {
        return;
    }

    let tabs = document.querySelectorAll('[data-tab-trigger]')

    let options = {
        searchEnabled: false,
        itemSelectText: '',
        shouldSort: false,
        choices: [
        ],
    }

    for (let i = 0; i < tabs.length; i++) {
        var val = tabs[i].dataset.tabTrigger;
        var label = tabs[i].dataset.label;

        options.choices.push({ value: val, label: label })
    }

    let choices = new Choices('.input-wrap--select select', options);

    if (searchParams.has('tab')) {
        var activeTab = searchParams.get('tab');
        choices.setChoiceByValue(activeTab);
    }

    selects.forEach((select, index) => {
        select.addEventListener(
            'change',
            function (choice) {
                if (choice.detail.value == "") {
                    choice.srcElement.parentElement.parentElement.classList.toggle('is-selected', false);
                }
                else {
                    choice.srcElement.parentElement.parentElement.classList.toggle('is-selected', true);
                }
            },
            false,
        );
    });

}


export default faq_choices

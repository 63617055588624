import jQuery from 'jquery'

const usp = (Splide) => {



    $('.usp-block .card').click(function () {
        const $this = jQuery(this);
        if ($this.hasClass('open')) return;

        //get all cards, set all of their icons to their "closed" src
        $this.siblings().removeClass('display-text');

        setTimeout(function () {
            $this.siblings().removeClass('open').addClass('closed');

            $('.usp-block .card').each(function (index) {
                const closedImageSrc = $(this).find('.card__icon').data('closed-image');
                $(this).find('.card__icon img').attr('src', closedImageSrc);
            });

            $this.find('.card__icon img').attr('src', $this.find('.card__icon').data('open-image'));

            $this.addClass('open').removeClass('closed');
        }, 300)

        setTimeout(function () {
            $this[0].classList.add('display-text');
        }, 600);
    });

    $('.usp-block .card:first-child').addClass('open display-text'); // add opened on first card

    var carousels = document.querySelectorAll('.usp-carousel');
    if(carousels.length > 0) {

        for(let i = 0; i < carousels.length; i++) {
            var splide = new Splide(carousels[i], { 
                perPage: 1,
                perMove: 1,
                pagination: false,
                gap: 20,
                padding: {
                    left: 0,
                    right: 120
                },
                arrows: false
            });
            var bar = splide.root.querySelector( '.usp-carousel-progress-bar' );

            // Update the bar width:
            splide.on('mounted move', function () {
                var end = splide.Components.Controller.getEnd() + 1;
                bar.style.width = String(100 * (splide.index + 1) / end) + '%';
            });
            splide.mount();
        }

    }


}


export default usp







// import jQuery from 'jquery'
// import Glider from 'glider-js'

// export default class UspBlock {

//     constructor() {

//         let windowWidth = window.innerWidth;

//         if (windowWidth > 768) {
//             this.desktop();
//         } else {

//             jQuery(document).ready(function ($) {
//                 $('.usp-block .card').each(function (index) {
//                     $(this).addClass('open display-text').removeClass('closed');

//                     const openImageSrc = $(this).find('.card__icon').data('open-image');
//                     $(this).find('.card__icon img').attr('src', openImageSrc);
//                 });

//             });

//             this.mobile();
//         }
//     }

//     desktop() {
//         jQuery(document).ready(function ($) {

//             $('.usp-block .card').click(function () {
//                 const $this = jQuery(this);
//                 if ($this.hasClass('open')) return;

//                 //get all cards, set all of their icons to their "closed" src
//                 $this.siblings().removeClass('display-text');

//                 setTimeout(function () {
//                     $this.siblings().removeClass('open').addClass('closed');

//                     $('.usp-block .card').each(function (index) {
//                         const closedImageSrc = $(this).find('.card__icon').data('closed-image');
//                         $(this).find('.card__icon img').attr('src', closedImageSrc);
//                     });

//                     $this.find('.card__icon img').attr('src', $this.find('.card__icon').data('open-image'));

//                     $this.addClass('open').removeClass('closed');
//                 }, 300)

//                 setTimeout(function () {
//                     $this[0].classList.add('display-text');
//                 }, 600);
//             });

//         });
//     }

//     mobile() {

//         let gliders = document.querySelectorAll('.usp-block__right');
//         let activeSlide = 0;

//         if (!gliders.length) {
//             return;
//         }

//         this.gliders = [];

//         Array.from(gliders).forEach((glider, index) => {

//             // Number of slides in the carousel.
//             const cards = glider.querySelectorAll('.usp-block__right .card');
//             const rangeSlider = glider.parentNode.querySelector('.usp-block-range-slider input[type="range"]');

//             this.gliders[index] = new Glider(glider, {
//                 slidesToShow: 1.1,
//                 slidesToScroll: 1,
//                 scrollLock: true,
//                 draggable: true,
//                 dragVelocity: 1,
//                 duration: 5
//             });

//             glider.addEventListener('glider-slide-visible', function (e) {
//                 let activeSlide = 0;
//                 let visibleSlides = 0;

//                 Array.from(cards).forEach((card, index) => {
//                     // Loop through all cards to get their classlist
//                     let classList = card.classList;

//                     // Loop through all the classes. If one is active then the index of this
//                     // element is the current active slide.
//                     for (var i = 0; i < classList.length; i++) {
//                         if (classList[i] === 'active') {
//                             activeSlide = index;
//                         }

//                         if (classList[i] === 'visible') {
//                             visibleSlides++;
//                         }
//                     }
//                 });

//                 let percentage = (activeSlide) / (cards.length - visibleSlides) * 100;

//                 if (activeSlide == 0) {
//                     percentage = 0;
//                 }

//                 event = document.createEvent('Event');
//                 event.initEvent('change', true, true);

//                 rangeSlider.value = percentage;
//                 rangeSlider.dispatchEvent(event);
//             });

//             rangeSlider.addEventListener('input', (e) => {
//                 // Get the width of the slider track
//                 let sliderWidth = glider.parentNode.querySelector('.glider-track').offsetWidth;
//             });

//         });

//     }



// }
import { createPopper } from '@popperjs/core';

const tooltip = () => {

    const els = document.querySelectorAll(".tooltip-toggle");

    els.forEach(el => {
        const alignment = el.getAttribute("data-alignment");
        const targetId = el.getAttribute("data-target");
        const target = document.querySelector(`#tooltip-${targetId}`);

        const popperInstance = createPopper(el, target, {
            placement: alignment,
            container: 'body',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['top', 'bottom', 'left', 'right']
                    }
                },
                //{
                //    name: 'preventOverflow',
                //    options: {
                //        altAxis: true
                //    }
                //}
            ],
        });

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach((event) => {
            el.addEventListener(event, () => {
                target.setAttribute('data-show', '');

                // We need to tell Popper to update the tooltip position
                // after we show the tooltip, otherwise it will be incorrect
                popperInstance.update();
            });
        });

        hideEvents.forEach((event) => {
            el.addEventListener(event, () => {
                target.removeAttribute('data-show');
            });
        });
    });
}

export default tooltip
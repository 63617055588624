
const video = () => {

  $('.video').parent().siblings('.playpause').on('click', function (ev) {

    var iframes = $(this).siblings().children('.video').children('iframe');
    for(let i = 0; i < iframes.length; i++) {
      iframes[i].src += '&autoplay=1&modestbranding=1&showinfo=0';
    } 


    var el = $(this)

    setTimeout(function() {

      el.fadeOut(); 
  
      el.siblings().children(".thumbnail").css({ 'opacity': '0', 'transition' : 'opacity 0.5s' , '-webkit-transition' : 'opacity 0.5s' });
      // $(this).siblings().children('.video').children('iframe').src += '&autoplay=1&amp;modestbranding=1&amp;showinfo=0';
      // $(this).siblings('.portrait-video').children('.video').children('iframe')[0].src += '&autoplay=1&amp;modestbranding=1&amp;showinfo=0';
      iframes.css('z-index', '99');
      ev.preventDefault();
    }, 300)

});


}


export default video
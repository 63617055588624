const navigation = () => {
    "use strict";


    $(".menu > ul > li:has( > .menu__dropdown)").addClass("menu-dropdown-icon");

    $(".menu > ul > li > ul:not(:has(.menu__dropdown))").addClass("normal-sub");

    $(".menu > ul > li").hover(function (e) {
        if ($(window).width() > 943) {
            $(this).children(".menu__dropdown").stop(true, false).fadeToggle(150);
            e.preventDefault();
        }
    });

    $(".menu__dropdown, .menu-dropdown-icon a").on("mouseover", function () {
        $(this).closest('.menu-dropdown-icon').children('a').addClass('menu-hover');
    });
    $(".menu__dropdown, .menu-dropdown-icon a").on("mouseleave", function () {
        $(this).closest('.menu-dropdown-icon').children('a').removeClass('menu-hover');
    });

    $(".menu > ul > li").on('click', function () {
        if ($(window).width() <= 943) {
            $(this).children(".menu__dropdown").fadeToggle(150);
            $(this).toggleClass("submenu-toggled");
        }
    });

    $(".burger").on('click', function (e) {
        $('body, html').toggleClass('menu-opened');
        $(".burger").toggleClass("open");
        $(".menu > .main-vis").toggleClass("show-on-mobile");
        $('.search-mobile').toggleClass('search-mobile--open');
        
        $('.search-vis').removeClass('search-vis--open');
        e.preventDefault();
    });

    $('.header__search').css("display", "flex").hide();
    $('.search-open').on('click', function() {
        $('.main-vis').fadeOut();        
        setTimeout(function(){
            $('.header__search').fadeIn();
            $('.searchSubmit').addClass('searchSubmit--active');
        }, 500)
    });

    $('.close-search').on('click', function() {
        $('.header__search').fadeOut();
        setTimeout(function(){
            $('.main-vis').fadeIn();
        }, 500)
    });

    $('.search-mobile').on('click', function() {
        $('.search-vis').addClass('search-vis--open');
    });

    $('.back-to-navigation').on('click', function(e) {
        e.preventDefault();
        $('.search-vis').removeClass('search-vis--open');
    });

    document.addEventListener("keyup", e => {
        if((e.target.name == "term" || e.target.name == "term_mobile" || e.target.name == "term_results") && e.key == "Enter")
        {
            const term = e.target.value;
            var searchParams = new URLSearchParams();
            searchParams.set("term", term);
            window.location = "/search?"+searchParams.toString();
        }
    });

    document.addEventListener("click", (e) => {
        if(e.target.classList.contains("searchSubmit"))
        {
            let term;
            if(e.target.classList.contains("searchSubmit-mobile"))
                term = document.querySelector("input[name='term_mobile']").value;
            else if (e.target.classList.contains("searchTermBtn"))
                term = document.querySelector("input[name='term_results']").value;
            else
                term = document.querySelector("input[name='term']").value;

            var searchParams = new URLSearchParams();
            searchParams.set("term", term);
            window.location = "/search?"+searchParams.toString();
            
        }
    });
    
}

export default navigation
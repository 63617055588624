const tab_carousel = () => {
    var tabs_carousels = $('.tabs-carousel');

    for (let i = 0; i < tabs_carousels.length; i++) {
        $(tabs_carousels[i]).find('.tabs-carousel--slide').first().fadeIn();
        $(tabs_carousels[i]).find('.tabs-carousel--tab').first().addClass('tabs-carousel--tab--active');
    }

    var transitioning = false;

    $('.tabs-carousel--tab').on('click', function () {
        if(!transitioning) {
            var tabTitle = $(this).data('tabtitle');
            $(this).parents('.tabs-carousel').find('.tabs-carousel--tab').removeClass('tabs-carousel--tab--active');
            $(this).toggleClass('tabs-carousel--tab--active');
            $(this).parents('.tabs-carousel').find('.tabs-carousel--slide').fadeOut(500);
            transitioning = true;
            setTimeout(function () {
                $('.tabs-carousel--slide[data-tabtitle="' + tabTitle + '"]').fadeIn(500, function(){
                    transitioning = false;
                });
            }, 500)
        }
    })

    const sliders = document.querySelectorAll('.tabs-carousel--tabs');
    if(sliders.length > 0) {

        for(let i = 0; i < sliders.length; i++) {
            let slider = sliders[i];
            let isDown = false;
            let startX;
            let scrollLeft;
        
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        }

    }
    

}

export default tab_carousel;
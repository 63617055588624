export default class input_text {

  constructor() {

		const textInputs = document.querySelectorAll('input[type="text"]:not(.ctrl__counter-input)');

		if( !textInputs.length ) {
			return;
		}

		Array.from(textInputs).forEach((input) => {
			input.addEventListener('focus', function(e) {
					e.target.parentNode.classList.add('hide-placeholder')
			}, false);

			input.addEventListener('blur', function(e) {
				if( !e.target.value ) {
					e.target.parentNode.classList.remove('hide-placeholder')
				}
			}, false);
		});

  }

}

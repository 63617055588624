const scroll_to_anchor = () => {

    // Adds the scroll class to buttons with the hash
    $('.button').each(function(){
        var url = $(this).attr('href');
        
        if(url != undefined) {
            var hash = url.split('#')[1];

            if(hash) {
                $(this).addClass('button--Scroll');
            }
        }
    });

    // The scrolling is now handled by CSS.
}

export default scroll_to_anchor;
const text_animations = () => {
    $(window).on('scroll load', function () {
        debounce(function () {
            // This is then function used to detect if the element is scrolled into view or is in view on load
            $('.animate-up').each(function () {
                if (elementScrolled(this)) {
                    // Add animation class when scrolled into view
                    $(this).addClass('animate-up--complete')
                }
            })
        }, 100)
    })

    $(window).on('load', function () {
        $('.animate-up').each(function () {
            if (elementScrolled(this)) {
                // Add animation class when scrolled into view
                $(this).addClass('animate-up--complete')
            }
        })
    })
}

function debounce(method, delay) {
    clearTimeout(method._tId)
    method._tId = setTimeout(function () {
        method()
    }, delay)
}

function elementScrolled(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height()
    var elemTop = $(elem).offset().top;
    return ((elemTop <= docViewBottom) && (elemTop >= docViewTop))
}

export default text_animations;
//imports here
import $ from "jquery";
import breadcrumbs from "./breadcrumbs";
import lottie_animations from "./lottie_animations";
import navigation from "./navigation";
import video from "./video";
import alert from "./alert";
import faqs from "./faqs";
import faq_choices from "./faq_choices";
import inputSlider from "./inputSlider";
import Calculator from "./calculator";
import hero_carousel from "./hero_carousel";
import tooltip from "./tooltip";
import usp from "./usp";
import tab_carousel from "./tab-carousel";
import list_accordion from "./list_accordion";
import css from "@splidejs/splide/dist/css/splide.min.css";
import Splide from "@splidejs/splide";
import scroll_to_anchor from "./scroll_to_anchor";
import text_animations from "./text_animations";
import gated_content from "./gated-content";
import contact_us from "./contact_us";

// form elements
import input_select from "./forms/input-select";
import input_text from "./forms/input-text";
import input_datepicker from "./forms/input-datepicker";

require("@fancyapps/fancybox");

// init()
// Equivalent to document ready
$(function () {
    navigation();
    lottie_animations();
    breadcrumbs();
    video();
    alert();
    faqs();
    faq_choices();
    inputSlider();
    new Calculator();
    hero_carousel(Splide);
    tooltip();
    tab_carousel();
    list_accordion();
    usp(Splide);
    input_select();
    new input_text();
    new input_datepicker();
    scroll_to_anchor();
    text_animations();
    gated_content();
    contact_us();
});

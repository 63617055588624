import Choices from 'choices.js'

const input_select = () => {

    let selects = document.querySelectorAll('.input-wrap--select select');
    selects.forEach((select, index) => {

        let choices = new Choices(select, {
            searchEnabled: false,
            itemSelectText: '',
            shouldSort: false,
        });

        select.addEventListener(
            'change',
            function (choice) {

                if (choice.target.value == "") {
                    choice.srcElement.parentElement.parentElement.classList.toggle('is-selected', false);
                }
                else {
                    choice.srcElement.parentElement.parentElement.classList.toggle('is-selected', true);
                }
            },
            false,
        );
    });
}

export default input_select;